import      Moment      from "moment";
import * as ReactRouter from "react-router-dom";



import      Image1    from "./assets/CaseStudy/Image1.png";
import      Image2    from "./assets/CaseStudy/Image2.jpg";
import      Image3    from "./assets/CaseStudy/Image3.jpg";
import      Image4    from "./assets/CaseStudy/Image4.jpg";
import      Image5    from "./assets/CaseStudy/Image5.jpg";
import      Image6    from "./assets/CaseStudy/Image6.jpg";
import * as NavBar    from "./NavBar";
import * as Home      from "./Home";
import * as AboutUs   from "./AboutUs";
import * as CaseStudy from "./CaseStudy";
import * as ContactUs from "./ContactUs";
import * as Footer    from "./Footer";
import * as Text      from "./Text";
import * as Card      from "./CaseStudy/Card";
import      Style     from "./style.module.scss";


const categories : Card.Types.T_Props[] =
[
    {
        id      : "CaseStudy1",
        title   : Text.caseStudyCategory1Title_EN,
        image   : Image1,
        date    : Moment("2021/5/1", "YYYY/MM/DD"),
        services: Text.caseStudyCategory1Services_EN,
        text    :
        [
            Text.caseStudyCategory1Text1_EN,
            Text.caseStudyCategory1Text2_EN,
            Text.caseStudyCategory1Text3_EN,
        ],
    },
    {
        id      : "CaseStudy2", 
        // url     : Text.caseStudyCategory2URL_EN, 
        title   : Text.caseStudyCategory2Title_EN,
        image   : Image2,
        date    : Moment("2024/4/1", "YYYY/MM/DD"),
        services: Text.caseStudyCategory2Services_EN,
        text    :
        [
            Text.caseStudyCategory2Text1_EN,
            Text.caseStudyCategory2Text2_EN,
            Text.caseStudyCategory2Text3_EN,
            Text.caseStudyCategory2Text4_EN,
            // Text.caseStudyCategory2Text5_EN,
        ],
    },
    {
        id      : "CaseStudy3",
        url     : Text.caseStudyCategory3URL_EN,
        title   : Text.caseStudyCategory3Title_EN,
        image   : Image3,
        date    : Moment("2024/5/1", "YYYY/MM/DD"),
        services: Text.caseStudyCategory3Services_EN,
        text    :
        [
            Text.caseStudyCategory3Text1_EN,
            Text.caseStudyCategory3Text2_EN,
            Text.caseStudyCategory3Text3_EN,
            Text.caseStudyCategory3Text4_EN,
            Text.caseStudyCategory3Text5_EN,
        ],
    },
    {
        id      : "CaseStudy4",
        title   : Text.caseStudyCategory4Title_EN,
        image   : Image4,
        date    : Moment("2022/2/1", "YYYY/MM/DD"),
        services: Text.caseStudyCategory1Services_EN,
        text    :
        [
            Text.caseStudyCategory4Text1_EN,
            Text.caseStudyCategory4Text2_EN,
            Text.caseStudyCategory4Text3_EN,
        ],
    },
    {
        id      : "CaseStudy5",
        title   : Text.caseStudyCategory5Title_EN,
        image   : Image5,
        date    : Moment("2021/12/1", "YYYY/MM/DD"),
        services: Text.caseStudyCategory5Services_EN,
        text    :
        [
            Text.caseStudyCategory5Text1_EN,
            Text.caseStudyCategory5Text2_EN,
            Text.caseStudyCategory5Text3_EN,
        ],
    },
    {
        id      : "CaseStudy6",
        title   : Text.caseStudyCategory6Title_EN,
        image   : Image6,
        date    : Moment("2021/3/1", "YYYY/MM/DD"),
        services: Text.caseStudyCategory6Services_EN,
        text    :
        [
            Text.caseStudyCategory6Text1_EN,
            Text.caseStudyCategory6Text2_EN,
        ],
    }
];


export function Component() : JSX.Element
{
	return (
		<div className={Style.Container}>
			
			<ReactRouter.BrowserRouter>
				<NavBar.Component/>
				<div className={Style.Content}>
					<ReactRouter.Routes>
						<ReactRouter.Route path={"/"                    } element={<Home.Component/>                             }/>
						<ReactRouter.Route path={"/AboutUs"             } element={<AboutUs.Component/>                          }/>
						<ReactRouter.Route path={"/CaseStudy"           } element={<CaseStudy.Component/>                        }/>
						<ReactRouter.Route path={"/CaseStudy/CaseStudy1"} element={<CaseStudy.Page.Component {...categories[0]}/>}/>
						<ReactRouter.Route path={"/CaseStudy/CaseStudy2"} element={<CaseStudy.Page.Component {...categories[1]}/>}/>
						<ReactRouter.Route path={"/CaseStudy/CaseStudy3"} element={<CaseStudy.Page.Component {...categories[2]}/>}/>
						<ReactRouter.Route path={"/CaseStudy/CaseStudy4"} element={<CaseStudy.Page.Component {...categories[3]}/>}/>
						<ReactRouter.Route path={"/CaseStudy/CaseStudy5"} element={<CaseStudy.Page.Component {...categories[4]}/>}/>
						<ReactRouter.Route path={"/CaseStudy/CaseStudy6"} element={<CaseStudy.Page.Component {...categories[5]}/>}/>
						<ReactRouter.Route path={"/ContactUs"           } element={<ContactUs.Component/>                        }/>
					</ReactRouter.Routes>
				</div>
				<div className={Style.Footer}>
					<Footer.Component/>
				</div>
			</ReactRouter.BrowserRouter>
			
		</div>
	);
};
