import * as React from "react";


import * as Text            from "../Text";
//import * as SphereAnimation from "../SphereAnimation";
import      Image1          from "../assets/AboutUs/Image1.jpg";
import      graph1          from "../assets/Graphs/graph1.png";
import      graph2          from "../assets/Graphs/graph2.png";
import      graph3          from "../assets/Graphs/graph3.png";
import      graph4          from "../assets/Graphs/graph4.png";
import      graph5          from "../assets/Graphs/graph5.png";
import      graph6          from "../assets/Graphs/graph6.png";
import      graph7          from "../assets/Graphs/graph7.png";
import      graph8          from "../assets/Graphs/graph8.png";
import      graph9          from "../assets/Graphs/graph9.png";
import      graph10         from "../assets/Graphs/graph10.png";
import      graph11         from "../assets/Graphs/graph11.png";
import      graph12         from "../assets/Graphs/graph12.png";
import      Style           from "./style.module.scss";


export function Component() : JSX.Element
{
    const [leftSphereRadius , setLeftSphereRadius ] = React.useState<number>(0);
    const [rightSphereRadius, setRightSphereRadius] = React.useState<number>(0);

    React.useEffect(() =>
    {
        function HandleResizeWindow() : void
        {
            function UpdateSphereSize(left : number, right : number) : void
            {
                setLeftSphereRadius (left);
                setRightSphereRadius(right);
            };

            if      (window.innerWidth <= 650 )  UpdateSphereSize(90 , 200);
            else if (window.innerWidth <= 850 )  UpdateSphereSize(120, 250);
            else if (window.innerWidth <= 1150)  UpdateSphereSize(150, 300);
            else if (window.innerWidth <= 1250)  UpdateSphereSize(180, 350);
            else if (window.innerWidth <= 1450)  UpdateSphereSize(210, 400);
            else if (window.innerWidth <= 1650)  UpdateSphereSize(240, 450);
            else if (window.innerWidth <= 1850)  UpdateSphereSize(270, 500);
            else                                 UpdateSphereSize(300, 550);
        };

        window.addEventListener("resize", HandleResizeWindow);

        HandleResizeWindow();

        return (() => { window.removeEventListener("resize", HandleResizeWindow); });
    }, []);

    return (
        <div className={Style.Container}>
            <div className={Style.PageTitle}>

                <div className={Style.CollapseGraph}>
                    <img src={graph10}/>
                </div>
                {/*
                <div className={Style.SphereContainerLeft}>
                    <SphereAnimation.Component
                        radius        = {`${leftSphereRadius}px`}
                        rotationX     = {"-110deg"}
                        rotationY     = {"145deg"}
                        color         = {"#555555"}
                        pulseStrength = {"2%"}
                        pulseDuration = {"13s"}
                        pulseDelay    = {".16s"}
                        pulseColor    = {"#777777"}
                    />
                </div>
                <div className={Style.SphereContainerRight}>
                    <SphereAnimation.Component
                        radius        = {`${rightSphereRadius}px`}
                        rotationX     = {"-120deg"}
                        rotationY     = {"-160deg"}
                        color         = {"#999999"}
                        pulseStrength = {"1.5%"}
                        pulseDuration = {"9s"}
                        pulseDelay    = {".11s"}
                        pulseColor    = {"#aaaaaa"}
                    />
                </div>
                */}
                <div className={Style.Title}>{Text.aboutUsPageTitle_EN.toUpperCase()}</div>
                <div className={Style.LeftBottomCorner}/>
            </div>
            <div className={Style.Content}>
                <div className={Style.Image}><img src={Image1}/></div>
                <div className={Style.TextSection}>
                    <div className={Style.Title}>{Text.aboutUsTitle_EN}</div>
                    <div className={Style.Text}>
                        <div className={Style.Paragraph}>{Text.aboutUsText1_EN}</div>
                        <div className={Style.Paragraph}>{Text.aboutUsText2_EN}</div>
                        <div className={Style.Paragraph}>{Text.aboutUsText3_EN}</div>
                        <div className={Style.Paragraph}>{Text.aboutUsText4_EN}</div>
                        <div className={Style.Paragraph}>{Text.aboutUsText5_EN}</div>
                        <div className={Style.Paragraph}>{Text.aboutUsText6_EN}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
