import      Image1              from "../../assets/Home/Image1.png";
import      Image2              from "../../assets/Home/Image2.png";
import      Image3              from "../../assets/Home/Image3.png";
import      Image4              from "../../assets/Home/Image4.png";
import * as Text                from "../../Text";
import * as VerticalInfoSection from "./VerticalInfoSection";
import      Style               from "./style.module.scss";


const infos : Omit<VerticalInfoSection.Types.T_Props, "side">[] =
[
    {
        image   : Image1,
        subTitle: Text.homeContentVerticalInfoSection1SubTitle_EN,
        content : Text.homeContentVerticalInfoSection1Content_EN,
    },
    {
        image   : Image2,
        subTitle: Text.homeContentVerticalInfoSection2SubTitle_EN,
        content : Text.homeContentVerticalInfoSection2Content_EN,
    },
    {
        image   : Image3,
        subTitle: Text.homeContentVerticalInfoSection3SubTitle_EN,
        content : Text.homeContentVerticalInfoSection3Content_EN,
    },
    {
        image   : Image4,
        subTitle: Text.homeContentVerticalInfoSection4SubTitle_EN,
        content : Text.homeContentVerticalInfoSection4Content_EN,
    }
];

export function Component() : JSX.Element
{
    return (
        <div className={Style.Container}>
            <div className={Style.FirstSection}>
                <div className={Style.Title}>{Text.homeContentFirstSectionTitle_EN}</div>
                <div className={Style.Text} >{Text.homeContentFirstSectionText_EN}</div>
            </div>

            <div className={Style.VerticalInfoSectionContainer}>
            {
                infos.map((info : Omit<VerticalInfoSection.Types.T_Props, "side">, index : number) : JSX.Element =>
                {
                    return (
                        <VerticalInfoSection.Component
                            key      = {`Home_${index}`}
                            image    = {info.image}
                            subTitle = {info.subTitle}
                            content  = {info.content}
                            side     = { (index % 2) ? VerticalInfoSection.Types.E_Side.LEFT : VerticalInfoSection.Types.E_Side.RIGHT }
                        />
                    );
                })
            }
            </div>
        </div>
    );
};
