import * as Text         from "../Text";
import      LinkedInLogo from "../assets/Footer/LinkedInLogo.svg";
import      GoTop        from "../assets/Footer/GoTop.svg";
import      Style        from "./style.module.scss";


export function Component() : JSX.Element
{
    function HandleOpenWebPage(url : string) : void
    {
        const newWindow : Window | null = window.open(url);

        if (newWindow)
            newWindow.focus();
    };

    function HandleOpenLinkedInPage() : void { HandleOpenWebPage(Text.linkedInURL);             };
    function GoToTopOfPage()          : void { window.scrollTo({ top: 0, behavior: "smooth" }); };

    return (
        <div className={Style.Container}>
            <div className={Style.Text}>{Text.footerText_EN.toUpperCase()}</div>
            <div
                className = {Style.LinkedInLogo}
                title     = {"LinkedIn"}
                onClick   = {HandleOpenLinkedInPage}
            >
                <img src={LinkedInLogo}/>
            </div>
            <div
                className = {Style.GoTop}
                onClick   = {GoToTopOfPage}
            >
                <img src={GoTop}/>
            </div>
        </div>
    );
};
