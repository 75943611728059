import * as React       from "react";
import * as ReactRouter from "react-router-dom";


import * as Page            from "../Page";
import * as Text            from "../../Text";
import * as SphereAnimation from "../../SphereAnimation";
import      graph1          from "../../assets/Graphs/graph1.png";
import      graph2          from "../../assets/Graphs/graph2.png";
import      graph3          from "../../assets/Graphs/graph3.png";
import      graph4          from "../../assets/Graphs/graph4.png";
import      graph5          from "../../assets/Graphs/graph5.png";
import      graph6          from "../../assets/Graphs/graph6.png";
import      graph7          from "../../assets/Graphs/graph7.png";
import      graph8          from "../../assets/Graphs/graph8.png";
import      graph9          from "../../assets/Graphs/graph9.png";
import      graph10         from "../../assets/Graphs/graph10.png";
import      graph11         from "../../assets/Graphs/graph11.png";
import      graph12         from "../../assets/Graphs/graph12.png";
import * as Types           from "./type";
import      Style           from "./style.module.scss";


export function Component(props : Types.T_Props) : JSX.Element
{
    const [leftSphereRadius , setLeftSphereRadius ] = React.useState<number>(0);
    const [rightSphereRadius, setRightSphereRadius] = React.useState<number>(0);

    React.useEffect(() =>
    {
        function HandleResizeWindow() : void
        {
            function UpdateSphereSize(left : number, right : number) : void
            {
                setLeftSphereRadius (left);
                setRightSphereRadius(right);
            };

            if      (window.innerWidth <= 650 )  UpdateSphereSize(150, 100);
            else if (window.innerWidth <= 650 )  UpdateSphereSize(180, 100);
            else if (window.innerWidth <= 850 )  UpdateSphereSize(200, 110);
            else if (window.innerWidth <= 1150)  UpdateSphereSize(250, 130);
            else if (window.innerWidth <= 1250)  UpdateSphereSize(300, 130);
            else if (window.innerWidth <= 1450)  UpdateSphereSize(350, 160);
            else if (window.innerWidth <= 1650)  UpdateSphereSize(400, 180);
            else if (window.innerWidth <= 1850)  UpdateSphereSize(450, 210);
            else                                 UpdateSphereSize(500, 240);
        };

        window.addEventListener("resize", HandleResizeWindow);

        HandleResizeWindow();

        return (() => { window.removeEventListener("resize", HandleResizeWindow); });
    }, []);

    function GoToPage() : void
    {
        if (props.url)
        {
            const newWindow : Window | null = window.open(props.url);

            if (newWindow)
                newWindow.focus();   
        }
    };

    return (
        <div className={Style.Container}>
            <div className={Style.PageTitle}>
                <div className={Style.CollapseGraph}>
                    <img src={graph5}/>
                </div>
            {/*
                <div className={Style.SphereContainerLeft}>
                    <SphereAnimation.Component
                        radius        = {`${leftSphereRadius}px`}
                        rotationX     = {"-110deg"}
                        rotationY     = {"145deg"}
                        color         = {"#999999"}
                        pulseStrength = {"2%"}
                        pulseDuration = {"13s"}
                        pulseDelay    = {".16s"}
                        pulseColor    = {"#aaaaaa"}
                        />
                </div>
                <div className={Style.SphereContainerRight}>
                    <SphereAnimation.Component
                        radius        = {`${rightSphereRadius}px`}
                        rotationX     = {"-120deg"}
                        rotationY     = {"-160deg"}
                        color         = {"#555555"}
                        pulseStrength = {"1.5%"}
                        pulseDuration = {"9s"}
                        pulseDelay    = {".11s"}
                        pulseColor    = {"#777777"}
                    />
                </div>
            */}
                <div className={Style.Title}>{props.title.toUpperCase()}</div>
                <div className={Style.LeftBottomCorner}/>
            </div>
            <div className={Style.Content}>
                <div className={Style.Top}>
                    <div className={Style.Details}>
                        <div className={Style.DetailContainer}>
                            <div className={Style.DetailTitle}>Client</div>
                            {
                                (props.client)
                                ?   <div className={Style.Detail}             >{props.client}</div>
                                :   <div className={Style.UndisclosableDetail}>{Text.caseStudyClientDefault}</div>
                            }
                        </div>
                        <div className={Style.DetailContainer}>
                            <div className={Style.DetailTitle}>Date</div>
                            <div className={Style.Detail}     >{props.date.format("MMMM YYYY")}</div>
                        </div>
                        <div className={Style.DetailContainer}>
                            <div className={Style.DetailTitle}>Services</div>
                            <div className={Style.Detail}     >{props.services}</div>
                        </div>
                    </div>
                    {(props.url) ? <button onClick={GoToPage}>Visit</button> : null}
                </div>
                <div className={Style.Bottom}>
                    <div className={Style.Text}>
                    {
                        props.text.map((paragraph : string) =>
                        {
                            return (<div className={Style.Paragraph}>{paragraph}</div>)
                        })
                    }
                    </div>
                </div>
            </div>
        </div>
    );
};

export * as Types from "./type";
