import * as Types from "./type";


export async function SendEmail(args : Types.T_EmailForm) : Promise<Response>
{
    console.log("ENTERED");
    const apiUrl : string = "https://api.brevo.com/v3/smtp/email";
    const apiKey : string = "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";
    
    const body =
    {
        sender:
        {
            name : "Website",//args.name,
            email: "sideral.nelo@gmail.com",//args.email,
        },
        to:
        [
            {
                email: "contact.sideral@gmail.com",
                name : "Sideral",
            }
        ],
        subject     : "Website contact",
        htmlContent : `<html><head></head><body><p>${args.name}</p><p>${args.email}</p><p>Phone Number: ${(args.phoneNumber ?? "Not provided")}</p>${args.content}</p></body></html>`,
    };

    const options =
    {
        method : "POST",
        headers:
        {
            "Accept"      : "application/json",
            "Content-Type": "application/json",
            "api-key"     : apiKey
        },
        body: JSON.stringify(body)
    };
    console.log("BONJOUR")
    return (fetch(apiUrl, options));
};
