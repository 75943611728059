import * as ReactRouter from "react-router-dom";


import * as Types from "./type";
import      Style from "./style.module.scss";


export function Component(props : Types.T_Props) : JSX.Element
{
    const navigate : ReactRouter.NavigateFunction = ReactRouter.useNavigate();

    function GoToCaseStudyPage() : void
    {
        if (props.id !== "")
        {
            navigate(
                `/CaseStudy/${props.id}`,
                {
                    state :
                    {
                        title   : props.title,
                        image   : props.image,
                        services: props.services,
                        client  : props.client,
                        date    : props.date.format("MMMM YYYY"),
                        url     : props.url, 
                        text    : props.text,
                    }
                }
            );
        }
        else
            navigate("/ContactUs");
    };

    return (
        <div className={Style.Container}>
            <div
                className = {Style.ImageContainer}
                onClick   = {GoToCaseStudyPage}
            >
                <img src={props.image}/>
            </div>
            <div className={Style.Title}>{props.title}</div>
        </div>
    );
};

export * as Types from "./type";
