import * as React       from "react";
import * as ReactRouter from "react-router-dom";
import      Moment      from "moment";


import * as Text            from "../Text";
import * as Card            from "./Card";
import * as SphereAnimation from "../SphereAnimation";
import      Arrow           from "../assets/CaseStudy/Arrow.svg";
import      Image1          from "../assets/CaseStudy/Image1.png";
import      Image2          from "../assets/CaseStudy/Image2.jpg";
import      Image3          from "../assets/CaseStudy/Image3.jpg";
import      Image4          from "../assets/CaseStudy/Image4.jpg";
import      Image5          from "../assets/CaseStudy/Image5.jpg";
import      Image6          from "../assets/CaseStudy/Image6.jpg";
import      graph1          from "../assets/Graphs/graph1.png";
import      graph2          from "../assets/Graphs/graph2.png";
import      graph3          from "../assets/Graphs/graph3.png";
import      graph4          from "../assets/Graphs/graph4.png";
import      graph5          from "../assets/Graphs/graph5.png";
import      graph6          from "../assets/Graphs/graph6.png";
import      graph7          from "../assets/Graphs/graph7.png";
import      graph8          from "../assets/Graphs/graph8.png";
import      graph9          from "../assets/Graphs/graph9.png";
import      graph10         from "../assets/Graphs/graph10.png";
import      graph11         from "../assets/Graphs/graph11.png";
import      graph12         from "../assets/Graphs/graph12.png";
import      Style           from "./style.module.scss";


const categories : Card.Types.T_Props[] =
[
    {
        id      : "CaseStudy1",
        title   : Text.caseStudyCategory1Title_EN,
        image   : Image1,
        date    : Moment("2021/5/1", "YYYY/MM/DD"),
        services: Text.caseStudyCategory1Services_EN,
        text    :
        [
            Text.caseStudyCategory1Text1_EN,
            Text.caseStudyCategory1Text2_EN,
            Text.caseStudyCategory1Text3_EN,
        ],
    },
    {
        id      : "CaseStudy2", 
        // url     : Text.caseStudyCategory2URL_EN, 
        title   : Text.caseStudyCategory2Title_EN,
        image   : Image2,
        date    : Moment("2024/4/1", "YYYY/MM/DD"),
        services: Text.caseStudyCategory2Services_EN,
        text    :
        [
            Text.caseStudyCategory2Text1_EN,
            Text.caseStudyCategory2Text2_EN,
            Text.caseStudyCategory2Text3_EN,
            Text.caseStudyCategory2Text4_EN,
            // Text.caseStudyCategory2Text5_EN,
        ],
    },
    {
        id      : "CaseStudy3",
        url     : Text.caseStudyCategory3URL_EN,
        title   : Text.caseStudyCategory3Title_EN,
        image   : Image3,
        date    : Moment("2024/5/1", "YYYY/MM/DD"),
        services: Text.caseStudyCategory3Services_EN,
        text    :
        [
            Text.caseStudyCategory3Text1_EN,
            Text.caseStudyCategory3Text2_EN,
            Text.caseStudyCategory3Text3_EN,
            Text.caseStudyCategory3Text4_EN,
            Text.caseStudyCategory3Text5_EN,
        ],
    },
    {
        id      : "CaseStudy4",
        title   : Text.caseStudyCategory4Title_EN,
        image   : Image4,
        date    : Moment("2022/2/1", "YYYY/MM/DD"),
        services: Text.caseStudyCategory1Services_EN,
        text    :
        [
            Text.caseStudyCategory4Text1_EN,
            Text.caseStudyCategory4Text2_EN,
            Text.caseStudyCategory4Text3_EN,
        ],
    },
    {
        id      : "CaseStudy5",
        title   : Text.caseStudyCategory5Title_EN,
        image   : Image5,
        date    : Moment("2021/12/1", "YYYY/MM/DD"),
        services: Text.caseStudyCategory5Services_EN,
        text    :
        [
            Text.caseStudyCategory5Text1_EN,
            Text.caseStudyCategory5Text2_EN,
            Text.caseStudyCategory5Text3_EN,
        ],
    },
    {
        id      : "CaseStudy6",
        title   : Text.caseStudyCategory6Title_EN,
        image   : Image6,
        date    : Moment("2021/3/1", "YYYY/MM/DD"),
        services: Text.caseStudyCategory6Services_EN,
        text    :
        [
            Text.caseStudyCategory6Text1_EN,
            Text.caseStudyCategory6Text2_EN,
        ],
    },
    {
        id      : "",
        title   : Text.caseStudyCategory7Title_EN,
        image   : Image1,
        date    : Moment(),
        services: "",
        text    : [],
    },
];


export function Component() : JSX.Element
{
    const navigate = ReactRouter.useNavigate();

    const [leftSphereRadius , setLeftSphereRadius ] = React.useState<number>(0);
    const [rightSphereRadius, setRightSphereRadius] = React.useState<number>(0);

    React.useEffect(() =>
    {
        function HandleResizeWindow() : void
        {
            function UpdateSphereSize(left : number, right : number) : void
            {
                setLeftSphereRadius (left);
                setRightSphereRadius(right);
            };

            if      (window.innerWidth <= 650 )  UpdateSphereSize(180, 90 );
            else if (window.innerWidth <= 650 )  UpdateSphereSize(200, 90 );
            else if (window.innerWidth <= 850 )  UpdateSphereSize(250, 120);
            else if (window.innerWidth <= 1150)  UpdateSphereSize(300, 150);
            else if (window.innerWidth <= 1250)  UpdateSphereSize(350, 180);
            else if (window.innerWidth <= 1450)  UpdateSphereSize(400, 210);
            else if (window.innerWidth <= 1650)  UpdateSphereSize(450, 240);
            else if (window.innerWidth <= 1850)  UpdateSphereSize(500, 270);
            else                                 UpdateSphereSize(550, 300);
        };

        window.addEventListener("resize", HandleResizeWindow);

        HandleResizeWindow();

        return (() => { window.removeEventListener("resize", HandleResizeWindow); });
    }, []);

    function GoToContactUs() : void { navigate("/contactUs"); };

    return (
        <div className={Style.Container}>
            <div className={Style.PageTitle}>
                <div className={Style.CollapseGraph}>
                    <img src={graph1}/>
                </div>
                {/*
                <div className={Style.SphereContainerLeft}>
                    <SphereAnimation.Component
                        radius        = {`${leftSphereRadius}px`}
                        rotationX     = {"-110deg"}
                        rotationY     = {"145deg"}
                        color         = {"#999999"}
                        pulseStrength = {"2%"}
                        pulseDuration = {"13s"}
                        pulseDelay    = {".16s"}
                        pulseColor    = {"#aaaaaa"}
                    />
                </div>
                <div className={Style.SphereContainerRight}>
                    <SphereAnimation.Component
                        radius        = {`${rightSphereRadius}px`}
                        rotationX     = {"-120deg"}
                        rotationY     = {"-160deg"}
                        color         = {"#444444"}
                        pulseStrength = {"1.5%"}
                        pulseDuration = {"9s"}
                        pulseDelay    = {".11s"}
                        pulseColor    = {"#656565"}
                    />
                </div>
                */}
                <div className={Style.Title}>{Text.caseStudyPageTitle_EN.toUpperCase()}</div>
                <div className={Style.LeftBottomCorner}/>
            </div>
            <div className={Style.Content}>
                <div className={Style.CardContainer}>
                {
                    categories.map((category : Card.Types.T_Props) : JSX.Element =>
                    {
                        return (
                            <div className={Style.Card}>
                            {
                                (category.id !== "")
                                ?   <Card.Component {...category}/>
                                :   <button onClick={GoToContactUs}>{category.title}<div><img src={Arrow}/></div></button>
                            }
                            </div>
                        );
                    })
                }
                </div>
            </div>
        </div>
    );
};

export * as Page from "./Page";
