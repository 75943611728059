import * as Types from "./type";
import      Style from "./style.module.scss";


export function Component(props : Types.T_Props) : JSX.Element
{
    return (<div className={`${Style.Container} ${(props.status === Types.E_Status.SUCCESS) ? Style.Success : Style.Failure}`}>{ props.message }</div>);
};

export * as Types from "./type";
