export enum E_Side
{
    LEFT,
    RIGHT,
};

export type T_Props =
{
    image    : string;
    subTitle : string;
    content  : string;
    side     : E_Side;
};
