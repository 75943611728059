import * as React       from "react";
import * as ReactRouter from "react-router-dom";


import * as Text           from "../../Text";
import      BurgerMenuLogo from "../../assets/NavBar/BurgerMenuLogo.svg";
import      Cross          from "../../assets/NavBar/Cross.svg";
import * as Types          from "./type";
import      Style          from "./style.module.scss";


export function Component(props : Types.T_Props) : JSX.Element
{
    const [open, setOpen] = React.useState<boolean>(false);

    React.useEffect(() =>
    {
        setOpen(props.isOpen);
    }, [props.isOpen]);

    function HandleOpenMenu()  : void { props.setOpen(true);  };
    function HandleCloseMenu() : void { props.setOpen(false); };

    return (
        <>
            <img
                className = {Style.Image}
                src       = {BurgerMenuLogo}
                onClick   = {HandleOpenMenu}
            />
            {
                (open)
                ?   <div className={Style.Container}>
                        <div className={Style.Panel}>
                            <div className={Style.Header}>
                                <img
                                    src     = {Cross}
                                    onClick = {HandleCloseMenu}
                                />
                            </div>
                            <div className={Style.Content}>
                                <ReactRouter.Link to={"/"}          onClick={HandleCloseMenu}>{Text.navBarSectionHome_EN     }</ReactRouter.Link>
                                <ReactRouter.Link to={"/AboutUs"}   onClick={HandleCloseMenu}>{Text.navBarSectionAboutUs_EN  }</ReactRouter.Link>
                                <ReactRouter.Link to={"/CaseStudy"} onClick={HandleCloseMenu}>{Text.navBarSectionCaseStudy_EN}</ReactRouter.Link>
                                <ReactRouter.Link to={"/ContactUs"} onClick={HandleCloseMenu}>{Text.navBarSectionContactUs_EN}</ReactRouter.Link>
                            </div>
                        </div>
                    </div>
                :   null
            }
        </>
    );
};
