import * as Spinner from "./spinner";
import      Style   from "./style.module.scss";


export function Component() : JSX.Element
{
    return (
        <div className={Style.Container}>
            <div className={Style.Spinner}><Spinner.Component/></div>
        </div>
    );
};
