import ReactDOM from "react-dom/client";


import * as App from "./App";

import './index.css';


const root : ReactDOM.Root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App.Component/>);
