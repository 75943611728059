import * as React    from "react";
import * as Calendy  from "react-calendly";


import * as Text            from "../Text";
import * as Email           from "./Email";
import * as CalendyLoader   from "./CalendyLoader";
import * as SphereAnimation from "../SphereAnimation";
import      graph1          from "../assets/Graphs/graph1.png";
import      graph2          from "../assets/Graphs/graph2.png";
import      graph3          from "../assets/Graphs/graph3.png";
import      graph4          from "../assets/Graphs/graph4.png";
import      graph5          from "../assets/Graphs/graph5.png";
import      graph6          from "../assets/Graphs/graph6.png";
import      graph7          from "../assets/Graphs/graph7.png";
import      graph8          from "../assets/Graphs/graph8.png";
import      graph9          from "../assets/Graphs/graph9.png";
import      graph10         from "../assets/Graphs/graph10.png";
import      graph11         from "../assets/Graphs/graph11.png";
import      graph12         from "../assets/Graphs/graph12.png";
import      Style           from "./style.module.scss"


const calendyStyle : React.CSSProperties | undefined =
{
    height : "100%",
    width  : "1000px",
};


export function Component() : JSX.Element
{
    const [show             , setShow             ] = React.useState<boolean>(false);
    const [leftSphereRadius , setLeftSphereRadius ] = React.useState<number>(0);
    const [rightSphereRadius, setRightSphereRadius] = React.useState<number>(0);

    React.useEffect(() => { setTimeout(() => { setShow(true); }, 3500); }, []);
    React.useEffect(() =>
    {
        function HandleResizeWindow() : void
        {
            function UpdateSphereSize(left : number, right : number) : void
            {
                setLeftSphereRadius (left);
                setRightSphereRadius(right);
            };

            if      (window.innerWidth <= 650 )  UpdateSphereSize(100, 150);
            else if (window.innerWidth <= 650 )  UpdateSphereSize(100, 180);
            else if (window.innerWidth <= 850 )  UpdateSphereSize(110, 200);
            else if (window.innerWidth <= 1150)  UpdateSphereSize(130, 250);
            else if (window.innerWidth <= 1250)  UpdateSphereSize(130, 300);
            else if (window.innerWidth <= 1450)  UpdateSphereSize(160, 350);
            else if (window.innerWidth <= 1650)  UpdateSphereSize(180, 400);
            else if (window.innerWidth <= 1850)  UpdateSphereSize(210, 450);
            else                                 UpdateSphereSize(240, 500);
        };

        window.addEventListener("resize", HandleResizeWindow);

        HandleResizeWindow();

        return (() => { window.removeEventListener("resize", HandleResizeWindow); });
    }, []);

    return (
        <div className={Style.Container}>
            <div className={Style.PageTitle}>
            <div className={Style.CollapseGraph}>
                <img src={graph2}/>
            </div>
            {/*
                <div className={Style.SphereContainerLeft}>
                    <SphereAnimation.Component
                        radius        = {`${leftSphereRadius}px`}
                        rotationX     = {"-110deg"}
                        rotationY     = {"145deg"}
                        color         = {"#999999"}
                        pulseStrength = {"2%"}
                        pulseDuration = {"13s"}
                        pulseDelay    = {".16s"}
                        pulseColor    = {"#aaaaaa"}
                        />
                </div>
                <div className={Style.SphereContainerRight}>
                    <SphereAnimation.Component
                        radius        = {`${rightSphereRadius}px`}
                        rotationX     = {"-120deg"}
                        rotationY     = {"-160deg"}
                        color         = {"#555555"}
                        pulseStrength = {"1.5%"}
                        pulseDuration = {"9s"}
                        pulseDelay    = {".11s"}
                        pulseColor    = {"#777777"}
                    />
                </div>
            */}
                <div className={Style.Title}>{Text.contactUsTitle_EN.toUpperCase()}</div>
                <div className={Style.LeftBottomCorner}/>
            </div>
            <div className={Style.Content}>
                <div className={Style.BookCall}>
                    <div className={Style.BookCallTitle}>{Text.contactUsBookACallTitle_EN.toUpperCase()}</div>
                    <div className={Style.BookCallCalendyFlex}>
                        <div className={Style.BookCallText}>
                            <div className={Style.Text}>{Text.contactUsBookACallText1_EN}</div>
                            <div className={Style.Text}>{Text.contactUsBookACallText2_EN}</div>
                            <div className={Style.Text}>{Text.contactUsBookACallText3_EN}</div>
                            <div className={Style.Text}>{Text.contactUsBookACallText4_EN}</div>
                        </div>
                        <div className={Style.BookCallCalendy}>
                            <div className={`${Style.Calendy} ${(show) ? Style.ShowCalendy : ""}`}>
                                <Calendy.InlineWidget
                                    url    = {"https://calendly.com/contact-sideral/how-can-we-help-you"}
                                    styles = {calendyStyle}
                                />
                            </div>
                            {(!show) ? <CalendyLoader.Component/> : null}
                        </div>
                    </div>
                </div>
                <div className={Style.EmailContainer}>
                    <div className={Style.EmailTitle}>{Text.contactUsEmailTitle_EN.toUpperCase()}</div>
                    <Email.Component/>
                </div>
            </div>
        </div>
    );
};
