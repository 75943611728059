export enum E_Status
{
    SUCCESS,
    FAILURE,
};

export type T_Props = 
{
    status  : E_Status;
    message : string;
};
