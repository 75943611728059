import * as PhoneInput from "react-international-phone";


export type T_PhoneMeta =
{
    country    : PhoneInput.ParsedCountry;
    inputValue : string;
};

export type T_PhoneInput =
{
    phone : string;
    meta  : T_PhoneMeta;
};


export enum E_SendEmailStatus
{
    IDLE,
    PENDING,
    FAILURE,
    SUCCESS,
};

export type T_EmailForm =
{
    name         : string;
    phoneNumber ?: string;
    email        : string;
    content      : string;
};

export type T_EmailCorrespondent =
{
    name  : string;
    email : string;
};

export type T_EmailData =
{
    sender      : T_EmailCorrespondent;
    to          : T_EmailCorrespondent[];
    subject     : string;
    htmlContent : string;
};
