import * as ReactRouter from "react-router-dom";


//import * as CollapseGraph from "./CollapseGraph";
import      CollapseGraph from "../assets/collapseGraph.png";
import * as Text          from "../Text";
import * as Content       from "./Content";
import      Style         from "./style.module.scss";


export function Component() : JSX.Element
{
    const navigate = ReactRouter.useNavigate();

    function GoToContactUs() : void { navigate("/contactUs"); };

    return (
        <div className={Style.Container}>
            <div className={Style.Header}>
                
                <div className={Style.CollapseGraph}>
                    <img src={CollapseGraph}/>{/*<CollapseGraph.Component/>*/}
                </div>

                <div className={Style.Information}>

                    <div className={Style.TextSection}>
                        <div className={Style.Title}>
                            <div>{`${Text.homeHeaderTextTitle_EN.split(",").slice(0, 2).join(",")},`}</div>
                            <div>{Text.homeHeaderTextTitle_EN.split(",").slice(2, 3)}</div>
                        </div>
                        <div className={Style.Text} >{Text.homeHeaderText_EN}</div>
                    </div>

                    <div className={Style.ButtonSection}>
                        <button onClick={GoToContactUs}>{Text.homeHeaderContactButton_EN.toUpperCase()}</button>
                    </div>

                </div>

            </div>
            
            <div className={Style.Content}>
                <Content.Component/>
            </div>
        </div>
    );
};
