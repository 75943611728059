import * as Types from "./type";
import      Style from "./style.module.scss";


export function Component(props : Types.T_Props) : JSX.Element
{
    return (
        <div className={`${Style.Container} ${(props.side === Types.E_Side.LEFT) ? Style.Left : Style.Right}`}>
            <div className={Style.Image}>
                <img src={props.image}/>
            </div>
            <div className={Style.Text}>
                <div className={Style.SubTitle}>{props.subTitle.toUpperCase()}</div>
                <div className={Style.Content} >{props.content}               </div>
            </div>
        </div>
    );
};

export * as Types from "./type";
