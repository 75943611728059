import * as React                from "react";
import * as PhoneInput           from "react-international-phone";
import * as GoogleLibPhoneNumber from "google-libphonenumber";


import * as Text     from "../../Text";
import * as Snackbar from "../SnackBar";
import      Loading  from "../../assets/ContactUs/Loading.svg"
import * as Endpoint from "./endpoint";
import * as Types    from "./type";
import      Style    from "./style.module.scss";


const phoneInputContainer : React.CSSProperties =
{
    width        : "100%",
    height       : "100%",
    borderRadius : "30px",
    display      : "flex",
    overflow     : "hidden",
    border       : "none",
};

const phoneInputCountrySelector : React.CSSProperties = 
{
    width  : "60px",
    outline: "none",
    border : "none",
};

const phoneInputCountrySelectorButtonContainer : React.CSSProperties =
{
    outline       : "none",
    border        : "none",
    display       : "flex",
    justifyContent: "center",
    alignItems    : "center",
    height        : "100%",
};

const phoneInputCountrySelectorButton : React.CSSProperties =
{
    width         : "100%",
    height        : "100%",
    background    : "#ffffff",
    border        : "none",
    outline       : "none",
    display       : "flex",
    justifyContent: "center",
    alignItems    : "center",
    cursor        : "pointer",
};

const phoneInputCountrySelectorFlag : React.CSSProperties =
{
    width: "30px",
};

const phoneInputDropDown : React.CSSProperties =
{
    position    : "absolute",
    zIndex      : 10,
    width       : "110%",
    maxWidth    : "300px",
    height      : "400px",
    overflow    : "scroll",
    borderRadius: "20px",
    margin      : "20px 10px",
    boxShadow   : "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
    outline     : "none",
    padding     : "0px",
    background  : "#ffffff",
};

const phoneInputDropDownItems : React.CSSProperties =
{
    width     : "100%",
    marginLeft: "0px",
    display   : "flex",
    alignItems: "center",
    gap       : "10px",
    padding   : "20px 20px",
    boxSizing : "border-box",
    cursor    : "pointer",
    fontSize  : "1.2rem",
};

const phoneInputInputContainer : React.CSSProperties =
{
    border    : "none",
    flexGrow  : 1,
    height    : "100%",
    padding   : "10px 5px 13px 5px",
    boxSizing : "border-box",
    outline   : "none",
};


export function Component() : JSX.Element
{
    const [status, setStatus] = React.useState<Types.E_SendEmailStatus>(Types.E_SendEmailStatus.IDLE);

    const [name        , setName        ] = React.useState<string>            (""        );
    const [errorName   , setErrorName   ] = React.useState<[boolean,string]>  ([false,""]);
    const [email       , setEmail       ] = React.useState<string>            (""        );
    const [errorEmail  , setErrorEmail  ] = React.useState<[boolean,string]>  ([false,""]);
    const [phone       , setPhone       ] = React.useState<Types.T_PhoneInput>(          );
    const [errorPhone  , setErrorPhone  ] = React.useState<[boolean,string]>  ([false,""]);
    const [content     , setContent     ] = React.useState<string>            (""        );
    const [errorContent, setErrorContent] = React.useState<[boolean,string]>  ([false,""]);

    function HandleOnChangeName(e    : React.ChangeEvent<HTMLInputElement>) : void { setName(e.currentTarget.value); };
    function IsNameValid       (name : string                             ) : boolean
    {
        if (name === "")
        {
            setErrorName([true, "Name is empty"]);
            
            return (false);
        }
        else
        {
            setErrorName([false, ""]);

            return (true);
        }
    };

    function HandleOnChangeEmail(e     : React.ChangeEvent<HTMLInputElement>) : void { setEmail(e.currentTarget.value); };
    function IsEmailValid       (email : string                             ) : boolean
    {
        const validEmailRegexp : RegExp = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);

        if (email === "")
        {
            setErrorEmail([true, "Email is empty"]);

            return (false);
        }
        else if (!validEmailRegexp.test(email))
        {
            setErrorEmail([true, "Email do not respect email format"]);

            return (false);
        }
        else
        {
            setErrorEmail([false, ""]);

            return (true);
        }
    };

    function HandleOnChangePhone(
        phone : string,
        meta  : Types.T_PhoneMeta,
    ) : void
    {
        setPhone({ phone : phone, meta : meta });
    };
    function IsPhoneValid(phone ?: Types.T_PhoneInput) : boolean
    {
        function CheckPhoneIsValid(phone : Types.T_PhoneInput) : boolean
        {
            const phoneUtil : GoogleLibPhoneNumber.PhoneNumberUtil = GoogleLibPhoneNumber.PhoneNumberUtil.getInstance();

            try
            {
                if (phone) return (phoneUtil.isValidNumber(phoneUtil.parse(phone.phone, phone.meta.country.iso2.toUpperCase())));
                else       return (false);
            }
            catch (error) { return (false); }
        };

        const phoneWithoutDial : string | undefined = phone?.phone.replace(`+${phone.meta.country.dialCode}`, "");

        if (phoneWithoutDial !== "" && phone && !CheckPhoneIsValid(phone))
        {
            setErrorPhone([true, "Phone is not valid"]);

            return (false);
        }
        else
        {
            setErrorPhone([false, ""]);

            return (true);
        }
    };
    
    function HandleOnChangeContent(e       : React.ChangeEvent<HTMLTextAreaElement>) : void { setContent(e.currentTarget.value); };
    function IsContentValid       (content : string                                ) : boolean
    {
        if (content === "")
        {
            setErrorContent([true, "Content is empty"]);

            return (false);
        }
        else
        {
            setErrorContent([false, ""]);
            
            return (true);
        }
    };

    function HandleOnSubmit() : void
    {
        if (status !== Types.E_SendEmailStatus.PENDING)
        {
            const isNameValid    : boolean = IsNameValid   (name   );
            const isEmailValid   : boolean = IsEmailValid  (email  );
            const isPhoneValid   : boolean = IsPhoneValid  (phone  );
            const isContentValid : boolean = IsContentValid(content);

            if (isNameValid && isEmailValid && isPhoneValid && isContentValid)
            {
                setStatus(Types.E_SendEmailStatus.PENDING);

                const IsPhoneEmpty : boolean = phone?.phone.replace(`+${phone.meta.country.dialCode}`, "") === "";

                Endpoint.SendEmail({ name : name, phoneNumber : (IsPhoneEmpty) ? undefined  : phone?.phone, email : email, content : content })
                .then(() =>
                {
                    setStatus(Types.E_SendEmailStatus.SUCCESS);
                    setName("");
                    setErrorName([false,""]);
                    setEmail("");
                    setErrorEmail([false,""]);
                    setPhone((prev : Types.T_PhoneInput | undefined) =>
                    {
                        if (prev) return ({phone : `+${prev.meta.country.dialCode}`, meta: {...prev.meta, inputValue: `+${prev.meta.country.dialCode} `}});
                        else      return (undefined);
                    });
                    setErrorPhone([false,""]);
                    setContent("");
                    setErrorContent([false,""]);
                    setTimeout(() => { setStatus(Types.E_SendEmailStatus.IDLE); }, 1500);
                })
                .catch(() =>
                {
                    setStatus(Types.E_SendEmailStatus.FAILURE);
                    setTimeout(() => { setStatus(Types.E_SendEmailStatus.IDLE); }, 1500);
                });
            }
        }
    };

    function HandleInputContentKeyDown(e : React.KeyboardEvent<HTMLTextAreaElement>) : void
    {
        if (e.key === "Tab")
        {
            e.preventDefault();

            const start : number = e.currentTarget.selectionStart;
            const end   : number = e.currentTarget.selectionEnd;
            const value : string = e.currentTarget.value;

            e.currentTarget.value          = value.substring(0, start) + '\t' + value.substring(end);
            e.currentTarget.selectionStart = e.currentTarget.selectionEnd = start + 1;
        }
    };
    
    return (
        <div className={Style.Container}>
            <div className={Style.Header}>
                <div className={Style.Input}>
                    <input
                        placeholder= {Text.contactUsEmailNamePlaceholder_EN}
                        value      = {name}
                        onChange   = {HandleOnChangeName}
                    />
                    {(errorName[0]) ? <div className={Style.ErrorMessage}>{errorName[1]}</div> : null}
                </div>
                <div className={Style.Input}>
                    <div className={Style.Label}>{Text.contactUsEmailOptional_EN}</div>
                    <PhoneInput.PhoneInput
                        style                     = {phoneInputContainer}
                        inputStyle                = {phoneInputInputContainer}
                        inputClassName            = {Style.PhoneInputInputContainer}
                        defaultCountry            = {"fr"}
                        value                     = {phone?.phone}
                        onChange                  = {HandleOnChangePhone}
                        countrySelectorStyleProps =
                        {
                            {
                                style                    : phoneInputCountrySelector,
                                buttonStyle              : phoneInputCountrySelectorButton,
                                flagStyle                : phoneInputCountrySelectorFlag,
                                buttonContentWrapperStyle: phoneInputCountrySelectorButtonContainer,
                                dropdownStyleProps: 
                                {
                                    style            : phoneInputDropDown,
                                    listItemStyle    : phoneInputDropDownItems,
                                    listItemClassName: Style.PhoneDialSelectorOption,
                                    listItemFlagStyle: phoneInputCountrySelectorFlag,
                                }
                            }
                        }
                    />
                    {(errorPhone[0]) ? <div className={Style.ErrorMessage}>{errorPhone[1]}</div> : null}
                </div>
                <div className={`${Style.Input} ${Style.Email}`}>
                    <input
                        placeholder= {Text.contactUsEmailEmailPlaceholder_EN}
                        value      = {email}
                        onChange   = {HandleOnChangeEmail}
                    />
                    {(errorEmail[0]) ? <div className={Style.ErrorMessage}>{errorEmail[1]}</div> : null}
                </div>
            </div>
            <div className={Style.Input}>
                <textarea
                    className   = {Style.Text}
                    value       = {content}
                    rows        = {20}
                    cols        = {50}
                    placeholder = {Text.contactUsEmailContentPlaceholder_EN}
                    lang        = {"fr"}
                    required    = {true}
                    spellCheck  = {true}
                    onChange    = {HandleOnChangeContent}
                    onKeyDown   = {HandleInputContentKeyDown}
                />
                {(errorContent[0]) ? <div className={Style.ErrorMessage}>{errorContent[1]}</div> : null}
            </div>
            <div className={Style.Footer}>
                <button
                    className={`${(status === Types.E_SendEmailStatus.PENDING) ? Style.Pending : ""}`}
                    onClick={HandleOnSubmit}
                >
                    {(status === Types.E_SendEmailStatus.PENDING) ? <div className={Style.Loading}><img src={Loading}/></div> : Text.contactUsEmailButtonPlaceholder_EN}
                </button>
            </div>
            {
                (status === Types.E_SendEmailStatus.FAILURE || status === Types.E_SendEmailStatus.SUCCESS)
                ?   <Snackbar.Component
                        status  = {(status === Types.E_SendEmailStatus.FAILURE) ? Snackbar.Types.E_Status.FAILURE : Snackbar.Types.E_Status.SUCCESS}
                        message = {(status === Types.E_SendEmailStatus.FAILURE) ? Text.contactUsSendEmailFailure_EN : Text.contactUsSendEmailSuccess_EN}
                    />
                :   null
            }
        </div>
    )
};
