import * as React       from "react";
import * as ReactRouter from "react-router-dom";


import      Logo   from "../assets/NavBar/logo_white_empty_fill.png";
import * as Text   from "../Text";
import * as Burger from "./Burger";
import      Style  from "./style.module.scss";


export function Component() : JSX.Element
{
    const location : ReactRouter.Location<any>    = ReactRouter.useLocation() ;
    const navigate : ReactRouter.NavigateFunction = ReactRouter.useNavigate() ;

    const [transparentBackground, setTransparentBackground] = React.useState<boolean>(true ) ;
    const [isNavBarFixed        , setIsNavBarFixed        ] = React.useState<boolean>(true ) ;
    const [openBurger           , setOpenBurger           ] = React.useState<boolean>(false) ;

    React.useEffect(() =>
    {
        function IsNavBarFixed() : void
        {
            if (location.pathname === "/") setIsNavBarFixed(() => { return (true ) ; });
            else                           setIsNavBarFixed(() => { return (false) ; });
        };

        function HandleOnScroll() : void
        {
            if (location.pathname === "/")
            {
                if (window.scrollY <= 70) setTransparentBackground(() => { return (true ) ; });
                else                      setTransparentBackground(() => { return (false) ; });   
            }
            else
                setTransparentBackground(() => { return (true); });
        };

        window.addEventListener("scroll", HandleOnScroll);
        HandleOnScroll() ;
        IsNavBarFixed () ;

        return (() => { window.removeEventListener("scroll", HandleOnScroll); });
    }, [location]);

    function GoToHome() : void 
    {
        setOpenBurger(false);
        navigate("/");
    };

    function HandleOpenBurger(value : boolean) : void
    {
        setOpenBurger(value);
    };

    return (
        <div 
            className=
            {`
                ${Style.Container}
                ${(transparentBackground) ? Style.TransparentBackground : ""}
                ${(isNavBarFixed)         ? Style.Fixed                 : ""}
            `}
        >
            <div
                className = {Style.Logo}
                onClick   = {GoToHome}
            >
                <div className={Style.Image}><img src={Logo}/></div>
                <div className={Style.Title}>Sideral</div>
            </div>
            <div className={Style.Categories}>
                <ReactRouter.Link to="/AboutUs"  >{Text.navBarSectionAboutUs_EN}</ReactRouter.Link>
                {/*<ReactRouter.Link to="/ourTeam"  >{Text.navBarSectionOurteam_EN}</ReactRouter.Link>*/}
                <ReactRouter.Link to="/CaseStudy">{Text.navBarSectionCaseStudy_EN}</ReactRouter.Link>
                <ReactRouter.Link to="/ContactUs">{Text.navBarSectionContactUs_EN}</ReactRouter.Link>
                <div className={Style.BurgerMenu}>
                    <Burger.Component
                        isOpen  = {openBurger}
                        setOpen = {HandleOpenBurger}
                    />
                </div>
            </div>
        </div>
    )
};
