export const navBarSectionHome_EN      : string = "Home";
export const navBarSectionAboutUs_EN   : string = "About Us";
export const navBarSectionOurteam_EN   : string = "Our team";
export const navBarSectionCaseStudy_EN : string = "Case Study";
export const navBarSectionContactUs_EN : string = "Contact Us";


/************************************** Home **************************************/


export const homeHeaderTextTitle_EN     : string = "Data, Knowledge, and Beyond";
export const homeHeaderText_EN          : string = "At Sideral we believe data is not to be handed over to a third-party company. We help you handle your own data on your own terms with state-of-the-art peer-to-peer technology.";
export const homeHeaderContactButton_EN : string = "Contact us";


export const homeContentFirstSectionTitle_EN : string =  "Empowering Your Business from Within";
export const homeContentFirstSectionText_EN  : string = "Through our excellence in systems design and software engineering, we provide our clients with effective architectures and analytics to let them make the most out of their data.";


export const homeContentVerticalInfoSection1SubTitle_EN : string = "Harmonizing and integrating complex systems";
export const homeContentVerticalInfoSection1Content_EN  : string = "We excel in harmonizing and integrating complex systems, ensuring seamless interoperability and optimal performance across diverse platforms. Our expertise enables us to unify disparate technologies into cohesive, efficient solutions tailored to meet the unique needs of our clients.";

export const homeContentVerticalInfoSection2SubTitle_EN : string = "Robust Security for Peace of Mind";
export const homeContentVerticalInfoSection2Content_EN  : string =  "Unlike regular third-party cloud solutions which require you to hand over your data to some tech giant, our solution allows you to readily build your own cloud, on your own infrastructure, at a minimal cost. This enables true data security and privacy, by making you the one and only owner and custodian of your data. Our priority is that your information is protected on your terms, with the highest security standards and cutting-edge technologies, for true peace of mind.";

export const homeContentVerticalInfoSection3SubTitle_EN : string = "Enabling collaboration between partner organizations";
export const homeContentVerticalInfoSection3Content_EN  : string = "Our most interesting and unique capability is to provide collaboration between partner organizations. We achieve this by building systems that federate data and produce knowledge through decentralization, and fine-grained, highly-secure customizability of what is shared. You decide what to share, when to share it, how to share it, and if necessary, when to stop sharing.";

export const homeContentVerticalInfoSection4SubTitle_EN : string = "IMPROVING DECISION-MAKING USING ML-POWERED REAL-TIME ANALYTICS";
export const homeContentVerticalInfoSection4Content_EN  : string = "Our credo is to \"never do twice work that has already been done once\". This cost- and time-effective approach leads us to intelligently update, store, and always make available the results from important analytics (such as machine learning models), in real-time, in the style of what is called a \"digital twin\" of your system.";


/************************************** AboutUs **************************************/


export const aboutUsPageTitle_EN : string = "Enter the new era of data sharing";
export const aboutUsTitle_EN     : string = "Our Vision";
export const aboutUsText1_EN     : string = "At Sideral, we believe that the future of data engineering lies in embracing new technologies and protocols that can unlock new possibilities and help our clients achieve their goals.";
export const aboutUsText2_EN     : string = "One area where we've seen a need for innovation is in the way that data is transmitted, analyzed and shared over the internet. There are severe limitations to modern clouds, in terms of security and efficiency, that prevent them from being able to solve a lot of research and industry problems.";
export const aboutUsText3_EN     : string = "Our approach is to leverage decentralized data federation. Our work is built on top of the latest advances in peer-to-peer networking, data compression, artificial intelligence, and encryption technologies.";
export const aboutUsText4_EN     : string = "This approach provides pathways for building highly customized, secure computer systems, capable of the same things as on-premise clouds, and more, but also reduces costs by leveraging existing infrastructure where possible.";
export const aboutUsText5_EN     : string = "Most importantly, it lets everyone be the true master of their own data, while also allowing for secure collaboration on data analytics between organizations.";
export const aboutUsText6_EN     : string = "We believe there is incredible value in this approach. If you're interested in learning more, don't hesitate to contact us.";


/************************************** CaseStudy **************************************/


export const caseStudyPageTitle_EN : string = "Our works";

export const caseStudyCategory1Title_EN    : string = "Research-Grade Technical Analysis And Consulting";
export const caseStudyCategory1Services_EN : string = "Business Audit";
export const caseStudyCategory1Text1_EN    : string = "An organization had commissioned an R&D report and prototype for state-of-the-art centralized data analysis system, with military-grade security, from a national laboratory. However, they found that they were unable to fully comprehend the complex technical details contained within the report, nor what was actually prototyped by the laboratory in question. They found themselves with their backs against the wall, not knowing how to justify this expensive purchase to their backers, with a very short time frame and little money to come up with a plausible excuse or alternative. The stakes of this mission were high. The organization was soon going to undergo an audit, and considering the seven figures paid for the commission, the results were surely going to be under a lot of scrutiny.";
export const caseStudyCategory1Text2_EN    : string = "Called by this organization, a team of four of our experts pored over the documents. Over a couple of days, they managed to isolate and distinguish the key findings, the essential engineering ideas, the recyclable software, and what was out of practical scope of doability in practice. Our team packaged these results in a clear report while working closely with the organization's own staff, providing them with the guidance, pedagogy and insights they needed to understand the report and prototypes' most immediately valuable assets, as well as the next steps to pursue this avenue.";
export const caseStudyCategory1Text3_EN    : string = "Thanks to our efforts, the organization was able to gain a complete understanding of the work they had commissioned, effectively unlocking the value of their million dollar investment. This successful engagement illustrates our competence and dedication to helping our clients achieve their goals, no matter how complex the challenge.";

export const caseStudyCategory2Title_EN    : string = "Business Logistics and Management";
export const caseStudyCategory2Services_EN : string = "Data Analytics Platform";
export const caseStudyCategory2Text1_EN    : string = "Managing inventory is a time-consuming, complex, and often time-critical task. Generally, what our clients desire is just-in-time inventory management, to minimize use of space, as well as potential waste of perishables. Additionally, being able to analyze what and how much you've sold, as well as when you sold it, creates a litany of rich data that provides important insights into various decisions that a business needs to make.";
export const caseStudyCategory2Text2_EN    : string = "A good example of this use case is restaurants. To address this, we've developed a comprehensive restaurant management system that empowers our clients to take control of their operations and maximize profitability. Our system includes a range of powerful features, such as customizable dashboards that allow restaurant owners to track their KPIs in real-time. With our menu engineering tools, they can analyze the performance of their menu items, tables, and product categories, making data-driven decisions to optimize their offerings.";
export const caseStudyCategory2Text3_EN    : string = "Our innovative invoice recognition feature uses AI to automatically read and process invoices, saving our clients valuable time and effort while ensuring their stock levels are always up-to-date and accurate. To help our clients stay ahead of the curve, we use advanced mathematical models to predict stock requirements based on sales history. This enables them to order and manage inventory with confidence, ensuring they have the right products in stock to meet their customers' needs.";
export const caseStudyCategory2Text4_EN    : string = "We are proud to support restaurant owners and managers in succeeding and thriving in a competitive and dynamic industry. If you're interested in us adapting our software to your industry, don't hesitate to contact us.";
export const caseStudyCategory2Text5_EN    : string = "See a demo of the application with mock data by clicking the visit button";
export const caseStudyCategory2URL_EN      : string = "http://149.202.53.200:3000/";

export const caseStudyCategory3Title_EN    : string = "Teaching";
export const caseStudyCategory3Services_EN : string = "Education";
export const caseStudyCategory3Text1_EN    : string = "Education and training are at the heart of what we do. We believe that everyone should have access to the resources they need to learn and grow, both personally and professionally. That's why our engineers have created a range of educational material, including comprehensive guides, tutorials, and projects on the fundamentals of mathematics and software, notably for everything related to data. Most of these resources are available to everyone, free of charge, and are designed to be accessible and easy to understand.";
export const caseStudyCategory3Text2_EN    : string = "In today's fast-paced and ever-changing world, it's more important than ever for people, as professionals and citizens, to become literate in the technologies that shape our lives. By providing people with the knowledge and skills they need to succeed, we can help to build a more inclusive and equitable society. We're committed to being a part of that journey. Whether you're just starting out or you're looking to take your skills to the next level, we're here to help you every step of the way.";
export const caseStudyCategory3Text3_EN    : string = "Our vision, rather than to rely just on \"teaching\", is how to excel at \"pedagogical engineering\". Through what sequences of practices, readings, projects, or exercises, can a teacher help their student gain mastery as fast as possible ? How should one adapt course material to their audience ? How does one construct a social environment in which people are naturally led to desire learning ? These are some of the questions we ask ourselves constantly when building learning systems.";
export const caseStudyCategory3Text4_EN    : string = "Our engineers also provide paid technical (theoretical and practical) training on a wide range of subjects. Don't hesitate to contact us if this interests you for your business or institution. In particular, we truly excel at teaching higher mathematics to software engineers, and teaching software engineering to mathematicians.";

export const caseStudyCategory3Text5_EN    : string = "If you're curious, here is a small sample of our work. The following is a lexicon which teaches a wide range of subjects related to data. It has a reading key to help you isolate which concepts are important for your purposes, even if your profile is not a technical one.";
export const caseStudyCategory3URL_EN      : string = "https://github.com/Fulguritude/code-discovery-exercises/blob/main/data_sci_eng_ml/Data_Lexicon.pdf";

export const caseStudyCategory4Title_EN    : string = "Data Management";
export const caseStudyCategory4Services_EN : string = "Data Management";
export const caseStudyCategory4Text1_EN    : string = "Our engineering team tackled a challenging data management project for a client dealing with complex, sensitive data, dispersed across multiple sites and providers. The client needed a solution to gain valuable operational insights on a multi-faceted data park, while ensuring data protection at all times.";
export const caseStudyCategory4Text2_EN    : string = "Leveraging their extensive experience and expertise in data federation technology, our engineers developed a solution that met the client's unique needs. By keeping the data in place and creating secure and efficient analytics reports, they ensured that the data remained protected and accessible without needing to move it, but still made insights gleaned from this data in each hub available to the rest of the system, in a secure way.";
export const caseStudyCategory4Text3_EN    : string = "This project showcases the expertise and skills of our engineering team. We are dedicated to supporting and empowering them as they continue to address the challenges of a data-driven world, confident that they will deliver innovative and effective solutions for our clients.";

export const caseStudyCategory5Title_EN    : string = "Agent-based modelling";
export const caseStudyCategory5Services_EN : string = "Supply Chain Simulation";
export const caseStudyCategory5Text1_EN    : string = "Some of our engineers were approached by a research laboratory to provide a prototype of an agent-based model meant to study the logistics of a full supply chain, from resource aggregation to sales. The goal of the project was to showcase the power of our technology and demonstrate how it can be used to create a digital twin of a business, or even a system of businesses, enabling real-time analysis, alerting, and proactive decision-making.";
export const caseStudyCategory5Text2_EN    : string = "We leveraged our data federation technology to simulate, for specific products, the integration of data from multiple sources and create a comprehensive view of the supply chain. We then built analytics that provided insight into the behavior of the supply chain, including complex interactions and dependencies between its various components.";
export const caseStudyCategory5Text3_EN    : string = "While the project seemed too complex, and not lucrative enough, to be pursued at the time, the prototype still demonstrated the power of decentralized data federation technologies to our client. This project is a testament to the expertise and innovative spirit of our team. We're proud of the work that we do, and we're dedicated to push the limits of what can be done for organizations to succeed and thrive in a data-driven world.";

export const caseStudyCategory6Title_EN    : string = "Process Optimization";
export const caseStudyCategory6Services_EN : string = "Audit & Optimization";
export const caseStudyCategory6Text1_EN    : string = "Managing inventory is a time-consuming, complex, and often time-critical task. Generally, what our clients desire is just-in-time inventory management, to minimize use of space, as well as potential waste of perishables. Additionally, being able to analyze what and how much you've sold, as well as when you sold it, creates a litany of rich data that provides important insights into various decisions that a business needs to make.";
export const caseStudyCategory6Text2_EN    : string = "A good example of this use case is restaurants. To address this, we've developed a comprehensive restaurant management system that empowers our clients to take control of their operations and maximize profitability. Our system includes a range of powerful features, such as customizable dashboards that allow restaurant owners to track their KPIs in real-time. With our menu engineering tools, they can analyze the performance of their menu items, tables, and product categories, making data-driven decisions to optimize their offerings.";

export const caseStudyCategory7Title_EN : string = "The next one might be yours";

export const caseStudyClientDefault : string = "Undisclosable";

/************************************** ContactUs **************************************/


export const contactUsTitle_EN          : string = "Contact Us";
export const contactUsBookACallTitle_EN : string = "Book a call with us";
export const contactUsBookACallText1_EN  : string = "Ready to take your data engineering to the next level? We're here to help.";
export const contactUsBookACallText2_EN  : string = "Our team of data engineering experts has years of experience designing and implementing peer-to-peer data systems that help our clients overcome their biggest data challenges. Whether you're dealing with data silos, slow data transfer speeds, or inadequate data protection, we have the solutions you need to get back on track.";
export const contactUsBookACallText3_EN  : string = "If you're interested in discussing your data issues with one of our experts, we invite you to book a call using the calendar on this page. During the call, we'll listen to your concerns, answer your questions, and explore how our solutions can help you achieve your data goals.";
export const contactUsBookACallText4_EN  : string = "We believe that every data challenge is an opportunity for growth and innovation, and we're excited to help you unlock the full potential of your data.";

export const contactUsEmailOptional_EN           : string = "optional";

export const contactUsEmailTitle_EN              : string = "If you have a question :";
export const contactUsEmailNamePlaceholder_EN    : string = "Your Name";
export const contactUsEmailEmailPlaceholder_EN   : string = "Email";
export const contactUsEmailPhonePlaceholder_EN   : string = "Phone (optional)";
export const contactUsEmailContentPlaceholder_EN : string = "Your Message";
export const contactUsEmailButtonPlaceholder_EN  : string = "Send Message";

export const contactUsSendEmailSuccess_EN : string = "The email is sent successfully";
export const contactUsSendEmailFailure_EN : string = "An error occured, retry later";

/************************************** Footer **************************************/


export const footerText_EN : string = "Follow us";
export const linkedInURL   : string = "https://www.linkedin.com/company/103993271/admin/dashboard/";
